.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pie-chart-container {
  width: 100%; /* Set your desired width */
  height: "60vh"; /* Set your desired height */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
  margin: 10px;
}

.custom-hr {
  border: none;
  border-top: 2px solid #e0e0e0; /* Adjust the color and thickness as needed */
  margin: 10px 0; /* Add some vertical spacing */
}

/* Style the text */
.line-text {
  display: flex;
  align-items: center;
  margin: 30px 0; /* Add some vertical spacing */
  font-size: 20px;
  /* display: inline-block; Display as an inline-block element to wrap the text */
  background-color: #6c6f72; /* Set the background color */
  padding: 5px 10px; /* Adjust padding to control the size of the background box */
  color: #fff; /* Set text color */
  border-radius: 5px; /* Add rounded corners to the box */
}

.line-text::before,
.line-text::after {
  content: "";
  flex: 1;
  border-top: 2px solid #e0e0e0; /* Match the line color and thickness */
  margin: 0 10px; /* Adjust the horizontal spacing */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33vh;
}

.pie-chart-container-1 {
  width: 100%; /* Set your desired width */
  height: 100%; /* Set your desired height */
}

/* styles.css or your preferred CSS file */
.fixed-width-button {
  width: 200px; /* Set your desired fixed width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Truncate long text with an ellipsis */
}

.navbar-container {
  background-color: rgb(230, 229, 229) !important;
  padding: 0 1rem !important;
}

/* Small */
.small .react-colorful {
  width: 120px;
  height: 120px;
}
.small .react-colorful__hue {
  height: 20px;
}
